<!--  -->
<template>
  <div class="">
    <div class="ywlybanner">
      <img src="../assets/jjfa.jpg" alt="" />
    </div>

    <div class="jjfaContent">
      <div v-for="(item,index) in dataList" :key="index">
        <div class="jjfaTit" :class="item.title.length<10?'shi':item.title.length<11?'shi1':'da'">
          <div class="tt1">{{item.title}}</div>
          <div class="tt2">{{item.etitle}}</div>
          <img src="../assets/sanjiao.png" alt="" />
        </div>
        <div class="zz1">
          <div class="leftzz">
            {{item.introduction}}
          </div>

          <div class="rightzz">
            <img :src="'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId='+item.fileId" alt="">
          </div>
        </div>

        <div class="tiao"></div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dataList:[]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
    this.$api
      .wxcarIndustryPlanpage({})
      .then((res) => {
        // this.hzdwcontent = res.data
        this.dataList = res.data.rows
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.ywlybanner {
  // height: 280px;
  img {
    width: 100%;
    // height: 100%;
  }
}

.jjfaContent {
  // width: 1560px;
  padding-bottom: 98px;
}
.shi{
 max-width: 350px !important;
}
.da{
   max-width: 500px;
}
.shi1{
  max-width: 400px ;
}
.jjfaTit {
 
  margin-top: 80px;
  border-bottom: 1px solid #237bff;
  padding-left: 356px;
  position: relative;
  .tt1 {
    font-size: 28px;
    color: #237bff;
    letter-spacing: 0.96px;
  }
  .tt2 {
    font-size: 14px;
    color: #237bff;
    letter-spacing: 0.58px;
  }
  img {
    width: 16px;
    height: 8px;
    position: absolute;
    right: -20px;
  }
}
.zz1 {
  width: 1200px;
  margin: auto;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  .leftzz {
    font-size: 16px;
    color: #333333;
    letter-spacing: 0.55px;
    line-height: 32px;
    width: 728px;
    min-height: 259px;
    text-indent:2em
  }
}
.leftzz div:nth-child(2) {
  margin-top: 12px;
}
.rightzz {
  width: 408px;
  height: 260px;
  // background: #000;
  img{
    width:100%;
    height:100%;
  }
}
.tiao {
  background: #237bff;
  width: 232px;
  height: 1px;
  margin-left: 856px;
}
</style>